<template>
  <div class="row organization-account-page">
    <div class="col-6">
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">{{ $t('address') }}</p>
        </div>
        <div v-if="loading" class="placeholder-wrap">
          <content-placeholders>
            <div class="row">
              <div class="col-11">
                <div class="row">
                  <div class="col-12 mb-3">
                    <p>
                      <content-placeholders-text :lines="1" />
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="mb-3">
                      <p>
                        <content-placeholders-text :lines="1" />
                      </p>
                    </div>
                    <div class="mb-3">
                      <p>
                        <content-placeholders-text :lines="1" />
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <p>
                        <content-placeholders-text :lines="1" />
                      </p>
                    </div>
                    <div class="mb-3">
                      <p>
                        <content-placeholders-text :lines="1" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </content-placeholders>
        </div>
        <div v-else class="card-body editable">
          <div class="row">
            <div class="col-12">
              <button @click="showFormEditLocation" class="button-edit-pen"></button>
              <div class="row">
                <div class="col-12 mb-3">
                  <p>
                    <span class="sf-text-strong mr-2">{{ $t('address') }}</span>
                    <span>{{ infoUser.organization ? infoUser.organization.address : '' }}</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('country') }}</span>
                      <span>{{ infoUser.organization ? infoUser.organization.country : '' }}</span>
                    </p>
                  </div>
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('city') }}</span>
                      <span>{{ infoUser.organization ? infoUser.organization.city : '' }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('province-state') }}</span>
                      {{ infoUser.organization ? infoUser.organization.province_state : '' }}
                    </p>
                  </div>
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('postal-zip') }}</span>
                      <span>{{
                        infoUser.organization ? infoUser.organization.postal_zip : ''
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">{{ $t('primary-contact') }}</p>
        </div>
        <div v-if="loading" class="placeholder-wrap">
          <content-placeholders>
            <div class="placeholder-content">
              <content-placeholders-text :lines="3" />
            </div>
          </content-placeholders>
        </div>
        <div v-else class="card-body editable">
          <div class="row">
            <div class="col-12">
              <button @click="showFormEditContact" class="button-edit-pen"></button>
              <div class="mb-3">
                <p>
                  <span class="sf-text-strong mr-2">{{ $t('name') }}</span>
                  <span> {{ infoUser.organization && infoUser.organization.name }}</span>
                </p>
              </div>
              <div class="mb-3">
                <p>
                  <span class="sf-text-strong mr-2">{{ $t('email') }}</span>
                  <span> {{ infoUser.organization && infoUser.organization.email }}</span>
                </p>
              </div>
              <div class="mb-3">
                <p>
                  <span class="sf-text-strong mr-2">{{ $t('phone') }}</span>
                  <span> {{ infoUser.organization ? infoUser.organization.phone : '' }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6">
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">{{ $t('resource-caps') }}</p>
        </div>
        <div v-if="loading" class="card-body">
          <content-placeholders>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <div class="mb-3">
                      <content-placeholders-text :lines="6" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <content-placeholders-text :lines="6" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </content-placeholders>
        </div>
        <div v-else class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('users') }}</span>
                      <span>{{
                        infoUser.organization
                          ? infoUser.organization.org_type.max_users + ' Users'
                          : ''
                      }}</span>
                    </p>
                  </div>
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('library') }}</span>
                      <span>
                        {{
                          infoUser.organization
                            ? infoUser.organization.org_type.max_media + ` ${$tc('asset', 2)}`
                            : ''
                        }}</span
                      >
                    </p>
                  </div>
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('campaigns') }}</span>
                      <span>
                        {{
                          infoUser.organization
                            ? infoUser.organization.org_type.max_campaigns + ` ${$t('campaigns')}`
                            : ''
                        }}</span
                      >
                    </p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('storage') }}</span>
                      <span
                        >{{
                          infoUser.organization ? infoUser.organization.org_type.max_data : ''
                        }}
                        GB</span
                      >
                    </p>
                  </div>
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('loops') }}</span>
                      <span>
                        {{ infoUser.organization ? infoUser.organization.org_type.max_loops : '' }}
                        {{ $t('loops') }}</span
                      >
                    </p>
                  </div>
                  <div class="mb-3">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('players') }}</span>
                      <span>
                        {{
                          infoUser.organization ? infoUser.organization.org_type.max_players : ''
                        }}
                        {{ $t('players') }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isParentOrg" class="col-12 col-sm-6 col-custom">
      <div class="card card-default card-information">
        <div class="card-heading">
          <p class="title-section">{{ $t('sub-organization-settings') }}</p>
        </div>
        <div v-if="loading" class="card-body sub-org-setting">
          <content-placeholders>
            <div class="row w-100">
              <div class="col-11">
                <content-placeholders-text :lines="4" />
              </div>
            </div>
          </content-placeholders>
        </div>
        <div v-else class="card-body sub-org-setting">
          <div class="row w-100">
            <div class="col-11">
              <div class="row mb-3">
                <div class="col-4">
                  <div class="card-body-icon">
                    <img src="@/assets/images/multiple-users-silhouette.svg" />
                    <span>{{ $t('billing') }}</span>
                  </div>
                </div>
                <div class="col-8">
                  <span class="card-content">{{
                    infoUser.organization && infoUser.organization.self_billing ? 'Self' : 'Parent'
                  }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <div class="card-body-icon">
                    <img src="@/assets/images/folder-account.svg" />
                    <span>{{ $t('library') }}</span>
                  </div>
                </div>
                <div class="col-8">
                  <span class="card-content">{{
                    infoUser.organization && infoUser.organization.have_library ? 'Yes' : 'No'
                  }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <div class="card-body-icon">
                    <img src="@/assets/images/campaign-account.svg" />
                    <span>{{ $t('campaigns') }}</span>
                  </div>
                </div>
                <div class="col-8">
                  <span class="card-content">{{
                    infoUser.organization && infoUser.organization.have_campaign
                      ? $t('yes')
                      : $t('no')
                  }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <div class="card-body-icon">
                    <img src="@/assets/images/loop-account.svg" />
                    <span>{{ $t('loops') }}</span>
                  </div>
                </div>
                <div class="col-8">
                  <span class="card-content">{{
                    infoUser.organization && infoUser.organization.have_loop ? $t('yes') : $t('no')
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-1">
              <!-- <button class="button-edit-table"></button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Edit Location of Organization -->
    <SfModal
      :title="$t('modal.title.edit-address')"
      :width="786"
      closeOnOverlay
      :show.sync="isShownModalEditLocation"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('address') }}</label>
                </div>
                <gmap-autocomplete
                  class="sf-input-text"
                  @place_changed="getAddressData"
                  @input="updateOrganization.organization.address = $event.target.value"
                  :value="updateOrganization.organization.address"
                ></gmap-autocomplete>
              </div>
              <p
                v-if="!updateOrganization.organization.address && showError"
                class="error-text text-error"
              >
                {{ $t('enter-an-address') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('choose-your-country') }}</label>
                </div>
                <input v-model="updateOrganization.organization.country" class="sf-input-text" />
              </div>
              <p
                v-if="!updateOrganization.organization.country && showError"
                class="error-text text-error"
              >
                {{ $t('select-country') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('choose-a-province-state') }}</label>
                </div>
                <input
                  v-model="updateOrganization.organization.province_state"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!updateOrganization.organization.province_state && showError"
                class="error-text text-right"
              >
                {{ $t('select-province-state') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('city') }}</label>
                </div>
                <input v-model="updateOrganization.organization.city" class="sf-input-text" />
              </div>
              <p
                v-if="!updateOrganization.organization.city && showError"
                class="error-text text-right"
              >
                {{ $t('enter-a-city') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('postal-zip') }}</label>
                </div>
                <input
                  v-model="updateOrganization.organization.postal_zip"
                  class="sf-input-text"
                  @input="validate('postal_zip')"
                  :class="{ 'input-error-form': errors.postal_zip }"
                />
              </div>
              <p
                v-if="!updateOrganization.organization.postal_zip && showError"
                class="error-text text-right"
              >
                {{ $t('enter-a-postal-code-or-zip-code') }}
              </p>
              <p v-if="errors.postal_zip" class="error-text text-right">
                {{ $t('enter-a-valid-postal-code-or-zip-code') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button
                  class="sf-secondary"
                  type="button"
                  @click="isShownModalEditLocation = false"
                >
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="saveEditAddress">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <!-- Modal Edit Contact of Organization -->
    <SfModal
      :title="$t('modal.title.edit-contact')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEditContact"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('name') }}</label>
                </div>
                <input
                  v-model="updateOrganization.organization.name"
                  :placeholder="$t('name')"
                  class="sf-input-text"
                  :readonly="!isEditable()"
                  :disabled="!isEditable()"
                  :style="!isEditable() ? 'background-color: #EBEBE4; color: #C6C6C6' : ''"
                />
              </div>
              <p
                v-if="!updateOrganization.organization.name && showError"
                class="error-text text-right"
              >
                {{ $t('enter-an-organization-name') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  v-model="updateOrganization.organization.email"
                  :placeholder="$t('email')"
                  class="sf-input-text"
                  v-on:keyup="checkEmailExist(updateOrganization.organization.email)"
                />
              </div>
              <p
                v-if="!updateOrganization.organization.email && showError"
                class="error-text text-right"
              >
                {{ $t('enter-an-email-address') }}
              </p>
              <p
                v-else-if="!checkEmail(updateOrganization.organization.email) && showError"
                class="error-text text-right"
              >
                {{ $t('enter-a-valid-email-address') }}
              </p>
              <p class="error-text text-right" v-if="!emailCanUsed">
                {{ $t('email-is-currently-used') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('phone') }}</label>
                </div>
                <input
                  v-model="updateOrganization.organization.phone"
                  :placeholder="$t('phone')"
                  class="sf-input-text"
                  @input="validate('phone')"
                  :class="{ 'input-error-form': errors.phone }"
                />
              </div>
              <p v-if="errors.phone" class="error-text text-right">
                {{ $t('enter-a-valid-phone') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEditContact = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="editContactAccount">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
  </div>
</template>

<script>
import SfModal from '@/components/SfModal.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import OrganizationService from '../../services/organization.service'
import AdminOrganizationService from '@/services/admin-organization.service.js'
import moment from 'moment-timezone'
export default {
  name: 'Organization',
  components: {
    SfModal
  },
  data() {
    return {
      isParentOrg: '',
      isShowModalEdit: false,
      user: '',
      idOrgan: 1,
      userID: 0,
      selected: '',
      isShownModalEdit: false,
      isShownModalEditLocation: false,
      isShownModalEditContact: false,
      showError: false,
      updateOrganization: {
        organization: {
          name: '',
          email: '',
          phone: '',
          address: '',
          country: '',
          province_state: '',
          postal_zip: '',
          city: '',
          slug: ''
        }
      },
      errors: {
        postal_zip: false,
        phone: false
      },
      stateFilter: [],
      listCountries: [],
      emailCanUsed: false
    }
  },
  methods: {
    isEditable() {
      return this.infoUser.user_type === 'employee'
    },
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    getAllStates() {
      this.$store.dispatch('player/getAllStates')
    },
    ...mapMutations('user', {
      setDataUserToStore: 'SET_DATA_USER_TO_STORE'
    }),
    showFormEditOrganizationName() {
      this.isShownModalEdit = true
      this.showError = false
      this.setDataOrganization(this.updateOrganization, this.infoUser.organization)
    },
    editOrganizationName() {
      if (this.updateOrganization.organization.name) {
        OrganizationService.editOrganizationAccount(this.updateOrganization)
          .then((res) => {
            this.isShownModalEdit = false
            this.$toast.success('Organization name successfully updated')
            // this.setDataUserToStore(res.data)
            this.setCookiesAndUpdateHeader(res.data)
            this.getInfoUser()
          })
          .catch((err) => {
            this.isShownModalEdit = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showError = true
      }
    },
    showFormEditContact() {
      this.resetValidate()
      this.isShownModalEditContact = true
      this.showError = false
      this.setDataOrganization(this.updateOrganization, this.infoUser.organization)
      this.emailCanUsed = true
    },
    resetValidate() {
      this.errors = Object.keys(this.errors).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    },
    checkEmail(email) {
      // eslint-disable-next-line
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    editContactAccount() {
      if (this.updateOrganization.organization.phone && !this.validate('phone')) return
      if (
        this.updateOrganization.organization.name &&
        this.updateOrganization.organization.email &&
        this.emailCanUsed &&
        this.checkEmail(this.updateOrganization.organization.email)
      ) {
        OrganizationService.editOrganizationAccount(this.updateOrganization)
          .then((res) => {
            this.isShownModalEditContact = false
            this.$toast.success('Contact successfully updated')
            this.setDataUserToStore(res.data)
            this.getInfoUser()
          })
          .catch((err) => {
            this.isShownModalEditContact = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showError = true
      }
    },
    showFormEditLocation() {
      this.resetValidate()
      this.isShownModalEditLocation = true
      this.showError = false
      this.setDataOrganization(this.updateOrganization, this.infoUser.organization)
    },
    getAddressData: function (place) {
      const self = this
      this.formatAddress(place.address_components)
      this.updateOrganization.organization.country = self.addressDataFormat.country
      this.updateOrganization.organization.province_state =
        self.addressDataFormat.administrative_area_level_1
      this.updateOrganization.organization.city = self.addressDataFormat.locality
      this.updateOrganization.organization.address = place.formatted_address
      this.updateOrganization.organization.postal_zip = self.addressDataFormat.postal_code
    },
    validate(type) {
      let validatePattern = ''
      switch (type) {
        case 'postal_zip':
          if (this.updateOrganization.organization.postal_zip) {
            validatePattern = /^[a-z0-9]+$/i
          }
          break
        case 'phone':
          if (this.updateOrganization.organization.phone) {
            validatePattern = /^[0-9().+\- ]{9,15}$/
          }
          break
      }
      if (validatePattern && !validatePattern.test(this.updateOrganization.organization[type])) {
        this.errors[type] = true
        return false
      }
      this.errors[type] = false
      return true
    },

    saveEditAddress() {
      if (
        this.updateOrganization.organization.address &&
        this.updateOrganization.organization.country &&
        this.updateOrganization.organization.province_state &&
        this.updateOrganization.organization.city &&
        this.updateOrganization.organization.postal_zip &&
        this.validate('postal_zip')
      ) {
        OrganizationService.editOrganizationAccount(this.updateOrganization)
          .then((res) => {
            this.$toast.success('Location successfully updated')
            this.isShownModalEditLocation = false
            // this.setDataUserToStore(res.data)
            this.getInfoUser()
          })
          .catch((err) => {
            this.isShownModalEditLocation = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showError = true
      }
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    setDataOrganization(newOrg, currentOrg) {
      newOrg.organization.name = currentOrg.name
      newOrg.organization.email = currentOrg.email
      newOrg.organization.phone = currentOrg.phone
      newOrg.organization.address = currentOrg.address
      newOrg.organization.province_state = currentOrg.province_state
      newOrg.organization.postal_zip = currentOrg.postal_zip
      newOrg.organization.city = currentOrg.city
      newOrg.organization.country = currentOrg.country
      newOrg.organization.slug = currentOrg.slug
    },
    setCookiesAndUpdateHeader(data) {
      const tokenExpired = this.$cookies.get('tokenExpired')
      const remember = this.$cookies.get('rememberMe') === 'true'
      if (tokenExpired && remember) {
        this.$cookies.set(
          'user',
          this.user,
          moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
        )
      } else {
        this.$cookies.set('user', this.user, 0)
      }
      this.$bus.$emit('update_joins_organization', {
        organization: { name: data.name }
      })
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
        this.userID = user.id
      }
    },
    checkEmailExist(email) {
      AdminOrganizationService.checkEmailOrgExist({
        organization_id: this.infoUser.organization.id,
        email: email
      }).then((res) => {
        this.emailCanUsed = res.data.can_use
      })
    }
  },
  channels: {
    AccountChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'update') {
          this.setDataUserToStore(data.data.organization)
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    this.getAllStates()
    this.getUser()
    this.$cable.subscribe({
      channel: 'AccountChannel',
      organization: this.$cookies.get('user').organization_id
    })
    this.isParentOrg = this.$cookies.get('user').is_parent_organization
  },
  computed: {
    ...mapState('user', {
      infoUser: 'infoUser',
      loading: 'loading'
    }),

    ...mapState('player', {
      allStates: 'allStates'
    }),
    ...mapGetters('player', {
      stateFromUSA: 'stateFromUSA',
      stateFromCanada: 'stateFromCanada'
    })
  },
  beforeDestroy() {
    this.$cable.unsubscribe('AccountChannel')
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import './accountPage.scss';
</style>
